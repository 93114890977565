.backgroundCtnr {
  display: flex;
  height: max-content;
  .backgroundImg{
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    right: 0;
    background: center no-repeat;
    filter: blur(10px);
    z-index: -1;
    transform: scale(2.0);
  }
  .backgroundVideo{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: -1;
    height: 100%;
    left: 0;
    transform: scale(1.3);
    overflow: hidden;
    filter: blur(3px);

  }

  .backgroundBlack {
    position: relative;
    height: max-content;
    width: 100%;
    left: 0;
    right: 0;
  }
}